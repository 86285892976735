<template>
  <!-- 顶部栏 -->
  <div class="top-bar" :class="{'is-home': isHome, 'not-home': !isHome}">
    <!-- 小屏导航栏按钮 -->
    <div class="top-bar-menu-btn">
      <el-button link @click="showSideNav = true">
        <i class="iconfont icon-menu"></i>&nbsp;
        <span>菜单</span>
      </el-button>
    </div>
     <!-- 网站Logo -->
    <div class="top-bar-logo">
      <router-link to="/">
        <img :src="isHome ? require('@/assets/logo/logo_white.png') : require('@/assets/logo/logo_black.png')"/>
      </router-link>
    </div>
    <!-- 大屏导航栏 -->
    <div class="top-bar-nav">
      <div class="nav-item" v-for="menuItem in navMenu" :key="menuItem.Code">
        <span class="nav-item-text">{{ menuItem.Code !== 'design' ? menuItem.Name : '在线选配系统' }}</span>
        <div class="nav-item-content" :style="{'height': menuItem.Code !== 'design' ? '100px' : '200px'}">
          <template v-if="menuItem.Code !== 'design'">
            <router-link class="plain-txt" v-for="subMenuItem in menuItem.data" :key="subMenuItem.ID" :to="subMenuItem.Url">
              {{ subMenuItem.Name }}
            </router-link>
          </template>
          <template v-else>
            <router-link class="image-txt" to="/design">
              <img src="@/assets/image/柜类选配.jpg"/>
              <div>柜类选配</div>
            </router-link>
            <router-link class="image-txt" to="/designer/plank/cabinet-door">
              <img src="@/assets/image/门板选配.jpg"/>
              <div>门板选配</div>
            </router-link>
            <router-link class="image-txt" to="/">
              <img src="@/assets/image/成品家居.jpg"/>
              <div>成品家居</div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="top-bar-func">
      <div class="func-user">
        <el-icon class="user-avatar" :size="24"><User/></el-icon>
        <div class="user-content">
          <template v-if="!store.getters.getToken">
            <div class="no-login">
              <el-button link @click="openLoginWin">登录/注册</el-button>
            </div>
          </template>
          <template v-else>
            <div class="top">
              <div>{{ store.getters.getPhone.substring(0, 3) + '****' + store.getters.getPhone.substring(7) }}</div>
            </div>
            <div class="middle">
              <ul>
                <li @click="showMyFavorite=true">
                  <i class="iconfont icon-scheme"></i>
                  <span>我的方案</span>
                </li>
                <li @click="openMyOrder">
                  <i class="iconfont icon-order"></i>
                  <span>我的订单</span>
                </li>
                <li @click="openShoppingCart">
                  <i class="iconfont icon-shopping-cart"></i>
                  <span>我的购物车</span>
                </li>
                <li v-if="store.getters.getUserPower.dealerDesign" @click="openDealerDesign">
                  <el-icon><Box/></el-icon>
                  <span>选配器</span>
                </li>
              </ul>
            </div>
            <div class="bottom">
              <ul>
                <li @click="logout">
                  <i class="iconfont icon-exit"></i>
                  <span>退出登录</span>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 小屏导航栏 -->
    <el-drawer class="top-bar-side-nav" v-model="showSideNav" size="50%" :with-header="false" :z-index="9999" @close="handleHideSideNavSubmenu">
      <div class="side-nav-action">
        <el-button link @click="handleHideSideNavSubmenu" :style="{'visibility': showSideNavSubmenu ? 'visible' : 'hidden'}">
          <el-icon :size="24"><ArrowLeft/></el-icon>
        </el-button>
        <el-button link @click="showSideNav = false">
          <el-icon :size="24"><Close/></el-icon>
        </el-button>
      </div>
      <div class="side-nav-logo">
        <router-link to="/" @click="showSideNav = false">
          <img src="@/assets/logo/logo_black.png"/>
        </router-link>
      </div>
      <Transition name="slide-rl">
        <ul class="side-nav-menu" v-show="!showSideNavSubmenu">
          <li v-for="(menuItem, index) in navMenu" :key="menuItem.Code" @click="handleShowSideNavSubmenu(index)">
            <span><i class="iconfont" :class="menuItem.IconClass"></i>{{ menuItem.Name }}</span>
            <el-icon :size="24"><ArrowRight/></el-icon>
          </li>
        </ul>
      </Transition>
      <Transition name="slide-lr">
        <ul class="side-nav-submenu" v-show="showSideNavSubmenu">
          <h1>{{ navMenu[activedSideNavSubmenuIndex].Name }}</h1>
          <template v-if="navMenu[activedSideNavSubmenuIndex].Code !== 'design'">
            <li v-for="subMenuItem in navMenu[activedSideNavSubmenuIndex].data" :key="subMenuItem.ID" @click="showSideNav = false">
              <router-link :to="subMenuItem.Url">{{ subMenuItem.Name }}</router-link>
            </li>
          </template>
          <template v-else>
            <li @click="showSideNav = false">
              <router-link to="/design">柜类选配</router-link>
            </li>
            <li @click="showSideNav = false">
              <router-link to="/designer/plank/cabinet-door">门板选配</router-link>
            </li>
            <li @click="showSideNav = false">
              <router-link to="/">成品家居</router-link>
            </li>
          </template>
        </ul>
      </Transition>
    </el-drawer>
    <MyFavorite v-if="showMyFavorite" @close="showMyFavorite=false"></MyFavorite>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { apiListNavMenu } from '@/api/homePage';
import axios from 'axios';
import MyFavorite from '@/components/MyFavorite.vue';

const store = useStore();
const route = useRoute();
const isHome = computed(() => route.path === '/');
const navMenu = ref([]);
const showSideNav = ref(false);
const showSideNavSubmenu = ref(false);
const activedSideNavSubmenuIndex = ref(0);
const showMyFavorite = ref(false);

function handleShowSideNavSubmenu(index) {
  activedSideNavSubmenuIndex.value = index;
  showSideNavSubmenu.value = true;
}

function handleHideSideNavSubmenu() {
  showSideNavSubmenu.value = false;
}

function openLoginWin() {
  const token = store.getters.getToken;
  if (!token) {
    store.commit('setLoginWin', true);
  }
}

function openMyOrder() {
  const token = store.getters.getToken
  if (!token) {
    store.commit('setLoginWin', true)
  } else {
    location.href = '/#/myorder'
  }
}

function openShoppingCart() {
  const token = store.getters.getToken
  if (!token) {
    store.commit('setLoginWin', true)
  } else {
    location.href = '/#/shoppingcart'
  }
}

const openDealerDesign = () => {
  const token = store.getters.getToken
  if (token) {
    const userCard = store.getters.getUserCard
    axios.request({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Custom-Token': token,
        'User-Card': userCard
      },
      url: 'http://api.yumeigd.com/account/quicklogin'
    }).then(function (res) {
      const data = res.data
      if (data.State === 'error') {
        ElMessageBox.alert(data.Msg, '提醒')
      } else {
        const url = 'http://design.yumeigd.com/#/loginin/' + data.Token
        location.href = url
      }
    })
  }
}

function logout() {
  store.commit('delToken');
  store.commit('setUserPowerDealerDesign', false);
  store.commit('delUserCard');
  store.commit('delNickName');
  store.commit('delPhone');
}

onBeforeMount(() => {
});

onMounted(() => {
  apiListNavMenu().then(res => {
    navMenu.value = res.data.data;
    navMenu.value.forEach(e => {
      switch(e.Code) {
        case 'about': e.IconClass = 'icon-logo'; break;
        case 'design': e.IconClass = 'icon-optional'; break;
        case 'mydesign': e.IconClass = 'icon-design'; break;
        case 'material': e.IconClass = 'icon-material'; break;
        case 'equipment': e.IconClass = 'icon-device'; break;
        case 'news': e.IconClass = 'icon-news'; break;
        case 'contact': e.IconClass = 'icon-location'; break;
      }
    });
  });
});
</script>

<style lang="scss" scoped>
$top-bar-height: 100px;
.top-bar {
  width: 100%;
  height: $top-bar-height;
  padding: 0 10%;
  border-bottom: 1px solid rgb(0,0,0,0.1);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  .top-bar-menu-btn {
    button {
      color: inherit;
      font-size: 16px;
    }
  }

  .top-bar-logo {
    img {
      width: 110px;
    }
  }

  .top-bar-nav {
    display: flex;
    .nav-item {
      height: $top-bar-height;
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .nav-item-text {
        border-bottom: 2px solid transparent;
      }
      .nav-item-content {
        width: 100%;
        border-top: 1px solid rgb(255,255,255,0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: $top-bar-height;
        left: 0;
        z-index: 10;
        visibility: hidden;
        cursor: auto;
        color: white;
        background-color: rgb(0,0,0,0.6);
        .plain-txt {
          padding: 5px 40px;
          margin: 0 10px;
          border: 1px solid rgb(255,255,255,0.1);
          border-radius: 18px;
          display: block;
          background-color: rgb(0,0,0,0.1);
        }
        .plain-txt:hover {
          border: 1px solid white;
          font-weight: bold;
        }
        .image-txt {
          width: 15%;
          max-width: 200px;
          margin: 0 10px;
          display: block;
          text-align: center;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .image-txt:hover {
          font-weight: bold;
        }
      }
    }
    .nav-item:hover {
      .nav-item-text {
        font-weight: bold;
      }
      .nav-item-content {
        visibility: visible;
      }
    }
  }

  .top-bar-func {
    display: flex;
    justify-content: flex-end;
    .func-user {
      display: inline-block;
      position: relative;
      .user-avatar {
        z-index: 20;
        border-radius: 50%;
        transition: transform 0.5s;
        cursor: pointer;
      }
      .user-content {
        width: 200px;
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        border-radius: 8px;
        box-shadow: 0px 1px 5px lightgrey;
        background-color: white;
        color: #333333;
        font-size: 14px;
        .top, .no-login {
          padding: 30px 0 10px 0;
          text-align: center;
        }
        .middle {
          border-top: 1px solid lightgrey;
        }
        .bottom {
          border-top: 1px solid lightgrey;
        }
        ul {
          padding: 10px 0;
          margin: 0;
          list-style-type: none;
          li {
            padding: 5px 15px;
            cursor: pointer;
            i {
              margin-right: 5px;
              font-size: 24px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
          li:hover {
            background-color: #F1F1F1;
          }
        }
      }
    }
    .func-user:hover {
      .user-avatar {
        color: black;
        background-color: white;
        transform: scale(2.5) translate(0px,5px);
      }
      .user-content {
        display: block;
      }
    }
  }

  :deep(.el-drawer.top-bar-side-nav) {
    color: #333333;
    .side-nav-action {
      display: flex;
      justify-content: space-between;
    }
    .side-nav-logo {
      margin: 30px 0;
      text-align: center;
      img {
        width: 110px;
      }
    }
    ul {
      width: calc(100% - 80px);
      padding: 0;
      margin: 0 20px;
      position: absolute;
      list-style-type: none;
      li {
        border-top: 1px solid lightgrey;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: 1px solid lightgrey;
      }
    }
    ul.side-nav-menu {
      li {
        padding: 20px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: transform 0.5s ease, opacity 0.5s ease;
        span {
          vertical-align: middle;
          i {
            margin-right: 5px;
            font-size: 24px;
            vertical-align: middle;
          }
        }
      }
    }
    ul.side-nav-submenu {
      transition: transform 0.5s ease, opacity 0.5s ease;
      h1 {
        margin: 0 0 20px 0;
        font-size: 24px;
      }
      li {
        a {
          padding: 20px 5px;
          display: block;
        }
      }
    }
  }
}

.top-bar.is-home {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background-color: rgb(0,0,0,0.6);
  .nav-item:hover {
    .nav-item-text {
      border-bottom-color: white;
    }
  }
}

.top-bar.not-home {
  color: #333333;
  background-color: white;
  .nav-item:hover {
    .nav-item-text {
      border-bottom-color: #333333;
    }
  }
}

.slide-rl-enter-active, .slide-rl-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-rl-enter-from, .slide-rl-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-rl-enter-to, .slide-rl-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.slide-lr-enter-active, .slide-lr-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-lr-enter-from, .slide-lr-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.slide-lr-enter-to, .slide-lr-leave-from {
  opacity: 1;
  transform: translateX(0);
}

a {
  color: inherit;
  text-decoration: none;
}

// 电脑
@media (min-width: 1200px) {
  .top-bar {
    .top-bar-menu-btn {
      display: none;
    }
    .top-bar-logo {
      flex: 1;
    }
    .top-bar-func {
      flex: 1;
    }
  }
}

// 平板
@media (min-width: 768px) and (max-width: 1199px) {
  .top-bar {
    .top-bar-nav {
      display: none;
    }
    .top-bar-menu-btn {
      flex: 1;
    }
    .top-bar-func {
      flex: 1;
    }
  }
}

// 手机
@media (max-width: 767px) {
  .top-bar {
    .top-bar-nav {
      display: none;
    }
    .top-bar-menu-btn {
      flex: 1;
    }
    .top-bar-func {
      flex: 1;
    }
    :deep(.el-drawer.top-bar-side-nav) {
      width: 100% !important;
    }
  }
}
</style>
