import { createStore } from 'vuex'

export default createStore({
  state: {
    loginWin: false,
    token: '',
    userPower: {
      dealerDesign: false
    },
    userCard: '',
    nickName: '',
    phone: '',
    ipInfo: {},
    shoppingCartIDs: []
  },
  getters: {
    getToken (state) {
      state.token = localStorage.getItem('token')
      return state.token || localStorage.getItem('token') || ''
    },
    getUserPower (state) {
      state.userPower.dealerDesign = localStorage.getItem('userPowerDealerDesign') === 'true'
      return state.userPower
    },
    getUserCard (state) {
      state.userCard = localStorage.getItem('usercard')
      return state.userCard || localStorage.getItem('usercard') || ''
    },
    getNickName (state) {
      state.nickName = localStorage.getItem('nickname')
      return state.nickName || localStorage.getItem('nickname') || ''
    },
    getPhone (state) {
      state.phone = localStorage.getItem('phone')
      return state.phone || localStorage.getItem('phone') || ''
    },
    getIpInfo (state) {
      state.ipInfo = localStorage.getItem('ipinfo')
      return state.ipInfo || localStorage.getItem('ipinfo') || ''
    },
    getShoppingCartIDs (state) {
      state.shoppingCartIDs = localStorage.getItem('shoppingCartIDs')
      return state.shoppingCartIDs || localStorage.getItem('shoppingCartIDs') || ''
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    delToken (state) {
      state.token = ''
      localStorage.removeItem('token')
    },
    setUserPowerDealerDesign (state, dealerDesign) {
      state.userPower.dealerDesign = dealerDesign
      localStorage.setItem('userPowerDealerDesign', dealerDesign)
    },
    setUserCard (state, userCard) {
      state.userCard = userCard
      localStorage.setItem('usercard', userCard)
    },
    delUserCard (state) {
      state.userCard = ''
      localStorage.removeItem('usercard')
    },
    setNickName (state, nickname) {
      state.nickName = nickname
      localStorage.setItem('nickname', nickname)
    },
    delNickName (state) {
      state.nickname = ''
      localStorage.removeItem('nickname')
    },
    setPhone (state, phone) {
      state.phone = phone
      localStorage.setItem('phone', phone)
    },
    delPhone (state) {
      state.phone = ''
      localStorage.removeItem('phone')
    },
    setLoginWin (state, loginWinIn) {
      state.loginWin = loginWinIn
    },
    setIpInfo (state, ipInfo) {
      state.ipInfo = ipInfo
      localStorage.setItem('ipinfo', ipInfo)
    },
    setShoppingCartIDs (state, ids) {
      state.shoppingCartIDs = ids
      localStorage.setItem('shoppingCartIDs', ids)
    },
    delShoppingCartIDs (state) {
      state.shoppingCartIDs = []
      localStorage.removeItem('shoppingCartIDs')
    }
  },
  actions: {
  },
  modules: {
  }
})
