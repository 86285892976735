<template>
  <div class="favorite-wrap">
    <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>我的设计方案</span>
        <el-button @click="close">
          <i class="iconfont icon-close"></i>
        </el-button>
      </div>
    </template>
    <div class="favorite-middle">
      <div class="favorite-left">
        <div class="design-sidenav">
          <div class="navTitle">柜类选配方案</div>
          <ul class="sidenav">
            <li v-for="(item,index) in menu.slice(0, 1)" :key="index" class="menuLi" v-bind:class="{'active': item.Active }">
              <div class="favorite-menu-item" @click="checkMenu(item, index)">
                {{item.Name}}
                <span class="icon" v-if="item.Active">+</span>
                <span class="icon" v-else>-</span>
              </div>
              <el-collapse-transition>
                <ul v-if="item.Unfold">
                  <li v-for="(itemSec, indexSec) in item.ChildMenu" :key="indexSec" class="menuSecLi" v-bind:class="{'active': showType === 'cabinet-wardrobe' && item.Active && childMenuAction === itemSec.ID}" @click="showType = 'cabinet-wardrobe'; loadDesign(item.ID,itemSec.ID);">
                    {{itemSec.Name}}
                  </li>
                </ul>
              </el-collapse-transition>
            </li>
          </ul>
          <div class="navTitle">门板选配方案</div>
          <ul class="sidenav">
            <li class="menuLi" :class="{'active': showType==='plank-cabinetDoor'}">
              <div class="favorite-menu-item" @click="showCabinetDoor">
                柜门
                <span class="icon" v-if="showType === 'plank-cabinetDoor'">+</span>
                <span class="icon" v-else>-</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="favorite-right">
        <!-- 柜类 -->
        <template v-if="showType === 'cabinet-wardrobe'">
          <div v-for="(item, index) in designs" :key="index" class="favorite-design-item">
            <swiper
              class="favorite-design-image"
              :modules="modules"
              navigation
              :pagination="{ clickable: true }"
            >
              <swiper-slide>
                <img :src="item.Image"/>
              </swiper-slide>
              <swiper-slide>
                <img :src="item.SideViewImage"/>
              </swiper-slide>
              <swiper-slide>
                <img :src="item.DoorImage"/>
              </swiper-slide>
            </swiper>
            <div class="favorite-design-other">
              <div class="row">
                <div class="favorite-design-button">
                  <button @click="designInfo(item)" title="查看">
                    <el-icon style="vertical-align: middle">
                      <View />
                    </el-icon>
                  </button>
                  <button @click="goView(item)" title="编辑">
                    <i class="iconfont icon-edit"></i>
                  </button>
                  <button @click="addToShoppingCart(item.ID)" title="添加到购物车">
                    <i class="iconfont icon-shopping-cart"></i>
                  </button>
                  <button @click="shareDesign(item.SerialNo)" title="分享">
                    <i class="iconfont icon-share"></i>
                  </button>
                  <button @click="delDesign(item.ID)" title="删除">
                    <i class="iconfont icon-delete"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="favorite-design-size">
                  {{item.Width + item.SideLeftWidth + item.SideRightWidth}}*{{item.Height}}*565 mm
                </div>
                <div class="favorite-design-time">
                  {{item.Time}}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 门板 -->
        <template v-else-if="showType === 'plank-cabinetDoor'">
          <ul class="scheme-list">
            <li class="scheme-list-item" v-for="n in 9" :key="n">
              <div class="item-preview text-type">
                <div class="preview-block" v-for="n in 4" :key="n">
                  <strong>见光板</strong>
                  <p>
                    <div v-for="n in 2" :key="n">左开门-2400*980-H1379-数量*3</div>
                    <div v-if="true">......</div>
                  </p>
                </div>
              </div>
              <div class="item-info">
                <div class="info-row">
                  <span class="row-left">柜门-方案一&nbsp;&nbsp;￥6658</span>
                  <span class="row-right">
                    <el-button link title="编辑">
                      <i class="iconfont icon-edit"></i>
                    </el-button>
                    <el-button link title="添加到购物车">
                      <i class="iconfont icon-shopping-cart"></i>
                    </el-button>
                    <el-popover trigger="click" popper-style="width:50px; min-width:10px; padding:5px; text-align:center;">
                      <template #reference>
                        <el-button link title="更多">
                          <i class="iconfont icon-more"></i>
                        </el-button>
                      </template>
                      <template #default>
                        <el-button link title="删除">
                          <i class="iconfont icon-delete"></i>
                        </el-button>
                      </template>
                    </el-popover>
                  </span>
                </div>
                <div class="info-row">
                  <span class="row-left">柜门*20、抽面*20、<br/>收口条*20、见光板*20</span>
                  <span class="row-right">2024-09-09 17:24</span>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </el-card>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'MyFavorite',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      menu: [],
      childMenu: [],
      designs: [],
      childMenuAction: '',
      showType: 'cabinet-wardrobe'
    })
    const loadDesign = (id, secMenu) => {
      const token = store.getters.getToken
      const userCard = store.getters.getUserCard
      state.childMenuAction = secMenu
      const data = {
        id: id,
        secMenu: secMenu
      }

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': token,
          'User-Card': userCard
        },
        url: 'http://api.yumeigd.com/MyFavorite/mydesign',
        transformRequest: [function (data) {
          return qs.stringify(data) // 请求前参数序列化
        }],
        data: data
      }).then(function (res) {
        state.designs = res.data.data
        console.log(res.data)
      })
    }
    const checkMenu = (menu, index) => {
      state.menu.forEach((e, i) => {
        if (i === index) {
          e.Active = !e.Active;
          e.Unfold = !e.Unfold;
        } else {
          e.Active = false;
          e.Unfold = false;
        }
      });
      emit('checkMenu', menu.ID)
    }
    const close = () => {
      emit('close', 'false')
    }
    const AutoLoadDesign = () => {
      const menu = state.menu
      if (menu.length > 0) {
        menu.forEach(function (ele, index, arr) {
          if (ele.Active) {
            if (ele.ChildMenu.length > 0) {
              loadDesign(ele.ID, ele.ChildMenu[0].ID)
            }
          }
        })
      } else {
        setTimeout(function () {
          AutoLoadDesign()
        }, 100)
      }
    }
    const designInfo = (menu) => {
      emit('close')
      const url = '#/designinfo/' + menu.ID
      location.href = url
    }
    const goView = (menu) => {
      emit('close')
      const url = '#/go/' + menu.SerialNo
      location.href = url
    }
    const addToShoppingCart = (id) => {
      // 添加到购物车
      const token = store.getters.getToken
      const userCard = store.getters.getUserCard

      // 保存
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': token,
          'User-Card': userCard
        },
        url: 'http://api.yumeigd.com/Shopping/Add?goodsType=cabinet&goodsID=' + id + '&qty=1'
      }).then(function (res) {
        const data = res.data
        if (data.State === 'ok') {
          emit('refresh')
        }
        ElMessageBox.alert(data.Msg, '提醒')
      })
    }
    const shareDesign = (serialNo) => {
      const url = 'http://www.yuemeigd.cn/#/go/' + serialNo
      const input = document.createElement('input') // 创建input对象
      input.value = url // 设置复制内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      ElMessage({
        showClose: true,
        message: '分享代码已复制到剪切板'
      })
      console.log(serialNo)
    }
    const delDesign = (id) => {
      ElMessageBox.confirm(
        '确定删除当前选配方案?',
        '提醒',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const token = store.getters.getToken
        const userCard = store.getters.getUserCard
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': token,
            'User-Card': userCard
          },
          url: 'http://api.yumeigd.com/MyFavorite/delDesign',
          transformRequest: [function (data) {
            return qs.stringify(data) // 请求前参数序列化
          }],
          data: { id: id }
        }).then(function (res) {
          const menu = state.menu
          menu.forEach(function (ele, index, arr) {
            if (ele.Active) {
              loadDesign(ele.ID, state.childMenuAction)
            }
          })
        })
      }).catch(() => {
      })
    }
    const loadData = () => {
      const token = store.getters.getToken
      const userCard = store.getters.getUserCard

      if (token) {
        // 菜单数据
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': token,
            'User-Card': userCard
          },
          url: 'http://api.yumeigd.com/MyFavorite/menu',
          data: 'id='
        }).then(function (res) {
          console.log(res)
          state.menu = res.data.data
          AutoLoadDesign()
        })
      }
    }

    const showCabinetDoor = () => {
      state.showType = 'plank-cabinetDoor';
      state.menu.forEach(e => {
        e.Active = false;
        e.Unfold = false;
      });
    };

    onMounted(() => {
      loadData()
    })
    return {
      ...toRefs(state),
      checkMenu,
      close,
      loadDesign,
      goView,
      designInfo,
      addToShoppingCart,
      shareDesign,
      loadData,
      delDesign,
      showCabinetDoor,
      modules: [Navigation, Pagination],
    }
  }
}
</script>

<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/myFavorite.scss";  //引入方式
</style>

<style lang="scss" scoped>
.favorite-wrap {

  .favorite-right {

    ul.scheme-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li.scheme-list-item {
        width: 31%;
        margin: 10px;

        .item-preview {
          border: 1px solid #E6E6E6;
          border-radius: 8px;
        }

        .item-preview.text-type {
          height: 210px;
          padding: 10px 20px;
          font-size: 10px;
          line-height: 1.5em;

          .preview-block {
            display: flex;

            strong {
              flex: 1;
            }

            p {
              margin: 0;
              flex: 4;
            }
          }
        }

        .item-info {
          font-size: 12px;

          .info-row {
            margin: 3px 0;
            display: flex;
            align-items: center;

            button {
              margin: 0 2px 0 0;
            }

            .row-left {
              flex: 1.5;
            }

            .row-right {
              flex: 1;
              align-self: flex-end;
              text-align: right;
            }
          }

          .info-row:nth-child(1) {
            span:first-child {
              font-weight: bold;
            }
          }

          .info-row:nth-child(2) {
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
